<template>
	<div id="ContactUs">
		<div class="banner">
			<el-image class="background" :src="banner" fit='cover'></el-image>
			<b class="mask"></b>
			<div class="main">
				<div class="cont">
					<p>联系我们</p>
					<b>项目周期：2023年3月-2023年12月</b>
					<b>主办单位：北京生命绿洲公益服务中心</b>
					<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" alt=""></b>
				</div>
			</div>
		</div>
		<div class="ContactDetails">
			<div class="info">
				<el-image :src="contact.imagePath" fit="cover"></el-image>
				<div class="right">
					<p>启航行动项目联系方式</p>
					<div class="Email">
						<span>Email</span>
						<b>{{contact.email}}</b>
					</div>
					<div class="Phone">
						<span>Phone</span>
						<b>{{contact.phone}}</b>
					</div>
				<!-- 	<div class="Address">
						<span>Address</span>
						<b>{{contact.address}}</b>
					</div> -->
				</div>
			</div>
			<div class="tit">
				<p>意见反馈</p>
				<b>Opinions and suggestions</b>
			</div>
			<el-form ref="form" :model="form" label-width="80px" label-position="top" :rules="rules">
				<el-row :gutter="50">
					<el-col :span="12">
						<el-form-item label="姓名" prop="name">
							<el-input clearable v-model="form.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Email" prop="email">
							<el-input clearable v-model="form.email"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="50">
					<el-col :span="12">
						<el-form-item label="电话" prop="phoneNumber">
							<el-input clearable v-model="form.phoneNumber"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="单位" prop="hospital">
							<el-input clearable v-model="form.hospital"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="50">
					<el-col :span="24">
						<el-form-item label="内容" prop="content">
							<el-input type="textarea" rows="6" v-model="form.content"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item class="opt">
					<el-button type="primary" @click="onSubmit('form')">立即提交</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		Contact, // 优秀稿件
		getConfig, // 首页获取logo banner 流程图  
		contactUs
	} from '@/request/api'
	export default {
		name: 'ContactUs',
		data() {
			return {
				banner: '',
				form: {
					hospital: '',
					name: '',
					phoneNumber: '',
					content: '',
					email: ''
				},
				rules: {
					name: {
						required: true,
						message: '请输入姓名',
						trigger: 'change|blur'
					},
					email: {
						required: false,
						message: '请输入邮箱',
						trigger: 'change|blur'
					},
					phoneNumber: {
						required: true,
						message: '请输手机号',
						trigger: 'change|blur'
					},
					hospital: {
						required: false,
						message: '请输所属机构',
						trigger: 'change|blur'
					},
					content: {
						required: true,
						message: '请输入内容',
						trigger: 'change|blur'
					},
				},
				contact: ''
			}
		},
		created() {
			this.getConfigFun();
			this.getContact();
		},
		mounted() {},
		methods: {
			// 提交意见和建议
			contactUsFun() {
				contactUs(this.form).then(res => {
					if (res.code == 200) {
						this.form = {};
						this.$notify({
							title: '成功',
							message: '提交成功，感谢您的意见和建议。',
							type: 'success',
							position: 'bottom-right'
						});
					} else {
						this.$notify({
							title: '失败',
							message: `${res.msg}`,
							type: 'error',
							position: 'bottom-right'
						});
					}
				})
			},


			// 获取联系我们展示信息
			getContact() {
				Contact().then(res => {
					if (res.code == 200) {
						this.contact = res.data;
					}
				})
			},

			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// alert('submit!');
						this.contactUsFun();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 首页获取logo banner 流程图
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.banner = res.data.bannerPath;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#ContactUs {
		.banner {
			min-height: 60vh;
			position: relative;

			.webHander {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 1;
			}

			.background {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 0;
			}

			.mask {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
			}

			.main {
				position: absolute;
				width: 1200px;
				top: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				padding-bottom: 150px;

				.cont {
					display: flex;
					flex-direction: column;

					p {
						font-size: 70px;
						font-weight: 600;
						line-height: 1.5;
					}

					b {
						font-size: 16px;
						font-weight: normal;
						margin-top: 12px;
						color: #000;
						opacity: .6;
						line-height: 1.5;

						img {
							height: 36px;
						}
					}
				}
			}
		}

		.ContactDetails {
			width: 1200px;
			margin: auto;
			display: flex;
			flex-direction: column;

			.tit {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin-bottom: 60px;

				p {
					font-size: 36px;
					text-align: center;
					color: rgba(31, 32, 34, 1);
				}

				b {
					font-size: 18px;
					text-align: center;
					color: rgba(203, 50, 44, 1);
				}
			}

			.info {
				padding: 100px 0;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.el-image {
					width: 560px;
					height: 560px;
					margin-right: 100px;
				}

				.right {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					p {
						font-size: 36px;
						color: rgba(47, 52, 71, 1);
					}

					>div {
						display: flex;
						flex-direction: column;

						span {
							font-size: 16px;
							color: rgba(137, 140, 158, 1);
						}

						b {
							font-size: 34px;
							color: rgba(47, 52, 71, 1);
						}
					}
				}
			}

			.el-form {
				.opt {
					text-align: center;

					.el-button {
						background-color: rgba(203, 50, 44, 1);
						border: none;
						width: 150px;
						height: 60px;
						border-radius: 30px;
						margin-top: 50px;
					}
				}
			}
		}
	}
</style>